@import './variables.css';

.signature-pad {
  margin: 20px auto;
  
}

.sigCanvas {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--light-bg-color);
  cursor: crosshair;
  width: 400px; /* Set to 100% to make it responsive */
  height: 200; /* Set an appropriate height */
}


.clear-button {
  margin-top: 10px;
  padding: 10px;
  color: var(--text-dark-color);
  background-color: var(--warning-bg-color);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}


/* Media query to adjust for smaller screens */
@media (max-width: 600px) {
  .sigCanvas {
    width: 300px; /* Ensure the canvas scales properly on smaller screens */
    height: 150px; /* Reduce height for better usability on small screens */
  }

}
